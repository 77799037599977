@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.stay-level-1 {
  transform: translateX(0%) !important;
}

.stay-level-1 ul.nav li:hover {
  background-color: transparent !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.of-di {
  overflow: auto !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}

/* 
.carousel-container {
  max-width: 600px;
  margin: 0 auto;
}

.carousel-slide {
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.carousel-content h2 {
  font-size: 24px;
}

.carousel-content p {
  font-size: 16px;
}



.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.slider--title {
  font-size: 2.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.slider--text {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  margin: 0.5rem 0;
}


.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.slider--btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s;
}
 */

.cssanimation,
.cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  color: white;
  font-weight: 700;
  display: inline-block;
  /* line-height: 1.2em; */
  font-weight: 700;
  color: rgb(255, 255, 255);
  opacity: 1;
  transform: translateY(0%);
  font-size: 1em;
}

.fadeInBottom {
  animation-name: fadeInBottom
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1
  }
}


.bnslider-slide1 {
  height: auto;
  /* max-height: 1080px; */
}

/* .bnslider-slide1 img {
  min-height: 1360px !important;
} */

.carousel.carousel-slider .control-arrow {
  background: #00a0df !important;
  border-radius: 50% !important;
  bottom: 0 !important;
  color: #fff !important;
  font-size: 26px !important;
  height: 48px !important;
  margin-top: 0 !important;
  opacity: 1 !important;
  padding: 0 !important;
  top: 47.5% !important;
  width: 48px;
}

.carousel .control-next.control-arrow {
  right: 15px !important;
}

.carousel .control-prev.control-arrow {
  left: 15px !important;
}


.control-dots {
  display: none !important;
}

.text-white {
  color: #fff !important;
}



@media screen and (max-width: 767px) {
  .bnslider-slide1 {
    height: 100vh;
    /* max-height: calc(100vh - 85px); */
    /* min-height: 625px !important; */
  }

  .home-page-slider-button-mobile-css .cssanimation span,
  .home-page-slider-button-mobile-css .cssanimation .btn-custom {
    width: 100%;
    border-radius: 0;
  }

  .carousel .slide img {
    border: 0;
    vertical-align: middle;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    object-fit: cover;
  }

  .event-title-page .carousel .slide img {
    border: 0;
    object-fit: cover;
    vertical-align: top;
    width: 100%;
    object-position: 0px 50px;
    height: auto;
  }

  .event-title-page .carousel .bnslider-slide1 {
    height: auto;
  }

  .event-title-page .carousel.carousel-slider {
    height: auto;
  }

  .carousel.carousel-slider {
    height: 100vh;
  }

  .carousel .slide img {
    border: 0;
    object-fit: cover;
    vertical-align: top;
    width: 100%;
    object-position: 0px;
  }


}


.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {

  content: none !important;

}

@media (max-width: 575px) {


  .carousel.carousel-slider .control-arrow {
    font-size: 26px !important;
    height: 48px !important;
    top: 85% !important;
    width: 48px !important;
  }

  .carousel .control-next.control-arrow {
    right: 35% !important;
  }

  .carousel .control-prev.control-arrow {
    left: 35% !important;
  }
}